import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import tw, { styled } from 'twin.macro'

import { StyledLink } from "@components/layout/style"
import { colorGold } from "@styles/color"

export const Logo = styled(StaticImage)`
  max-height: 82px;
  max-width: 216px;

  position: absolute;
  top: 12px;
`

export const MenuContainer = styled('div')`
  ${tw`flex items-center md:justify-between mb-6 lg:mb-0 lg:mr-6 flex-row sm:visible invisible`}
`

export const StyledLinkL = styled(StyledLink)`
  ${tw`text-base m-5`}
  letter-spacing: 1px;
`;


export const StyledLinkXL = styled(StyledLink)`
  ${tw`text-lg`}
  letter-spacing: 1px;
`;

export const StyledCaptionXL = styled('span')`
${tw`text-gray-800
text-lg font-semibold
antialiased tracking-wide
m-3 p-3
transition-colors duration-300
ease-in-out cursor-pointer`}
  &:hover {
    color: ${colorGold};
  }
  color: rgb(35, 35, 35);
  letter-spacing: 1px;
  font-weight: 700;
  text-transform: uppercase;
`;

export const HamburgerContainer = styled('div')`
${tw`sm:justify-center justify-between p-3 pb-0`}

  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  HamburgerMenu {
    display: block;
    @media (min-width: 640px) {
      display: none;
    }
  }
`;

const MobileMenu = styled('div')`
  ${tw`flex flex-col  bg-white p-4 flex-col overflow-x-auto overflow-y-auto `}

  ${StyledLinkL} {
    ${tw`pb-3  pt-0 m-0`}
  }
  ${StyledCaptionXL}{
    ${tw`text-center`}
  }
  ${StyledLinkXL}{
    ${tw`text-center`}
  }
`;

const MobileSubMenu = styled('div')`
  ${tw`flex flex-col pl-3 bg-gray-200`}
  ${StyledCaptionXL}{
    ${tw`text-center`}
  }
  ${StyledLinkXL}{
    ${tw`text-center`}
  }
`;


const GaleryTemplate:React.FC = ({data}) => {

  return (
  <MobileMenu>
    <StyledLinkXL to="/mm" >Oferta</StyledLinkXL>


        <MobileSubMenu className="flex flex-col pl-3" >
          <StyledLinkXL to="/oferta/implantologia-wagrowiec">Implantologia</StyledLinkXL>
          <StyledLinkXL to="/oferta/ortodoncja-wagrowiec">Ortodoncja</StyledLinkXL>
          <StyledLinkXL to="/oferta/protetyka-wagrowiec">Protetyka stomatologiczna</StyledLinkXL>
          <StyledLinkXL to="/oferta/endodoncja-wagrowiec">Endodoncja</StyledLinkXL>
          <StyledLinkXL to="/oferta/stomatologia-estetyczna">Stomatologia estetyczna</StyledLinkXL>
          <StyledLinkXL to="/oferta/stomatologia-chirurgiczna">Stomatologia chirurgiczna</StyledLinkXL>
          <StyledLinkXL to="/oferta/periodontologia">Choroby przyzębia</StyledLinkXL>
          <StyledLinkXL to="/oferta/stomatologia-zachowawcza">Stomatologia zachowawcza</StyledLinkXL>
          <StyledLinkXL to="/oferta/stomatologia-dziecieca">Stomatologia dziecieca</StyledLinkXL>
          <StyledLinkXL to="/oferta/sedacja-wziewna">Sedacja wziewna</StyledLinkXL>
        </MobileSubMenu>


    <StyledLinkXL to="/zespol" >Zespół</StyledLinkXL>
    <StyledLinkXL to="/cennik" >Cennik</StyledLinkXL>
    <StyledLinkXL to="/galeria" >Galeria</StyledLinkXL>
    <StyledLinkXL to="/kontakt" >Kontakt</StyledLinkXL>
  </MobileMenu>);
};

export default GaleryTemplate;

